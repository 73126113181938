import React, { useState } from "react";
import { Button, Form, Container, Row, Col, Toast } from "react-bootstrap";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import LoadingSpinner from "../LoadingSpinner";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "", variant: "" });
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.status === "accepted") {
          setToast({
            show: true,
            message: "Login successful!",
            variant: "success",
          });
          setTimeout(() => navigate("/user"), 2000);
        } else if (userData.status === "pending") {
          setToast({
            show: true,
            message: "Your account is not approved by the admin yet.",
            variant: "danger",
          });
          await auth.signOut();
        } else if (userData.status === "rejected") {
          setToast({
            show: true,
            message: "Your account request is rejected by the admin.",
            variant: "danger",
          });
          await auth.signOut();
        }
      } else {
        setToast({
          show: true,
          message: "User data not found.",
          variant: "danger",
        });
      }
    } catch (error) {
      setToast({
        show: true,
        message: "Error logging in: " + error.message,
        variant: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
  

      <Container
        fluid="sm"
        className=" d-flex justify-content-center align-items-center pt-10 overflow-hidden"
      >
        <Row className="w-100 justify-content-center">
          <Col md={4}>
            <div className="login-box p-4 text-center">
              <h2 className="mb-4  fs-5 ">Login to Mountain Insert Hub</h2>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button
                  variant="dark"
                  type="submit"
                  className="w-100 mb-3"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Log In"}
                </Button>
              </Form>

              <p className="mt-4">
                Don't have an account?{" "}
                <Link to="/sign-up" className="text-primary">
                  Sign Up
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Bootstrap Toast for displaying error/success messages */}
      <Toast
        show={toast.show}
        onClose={() => setToast({ ...toast, show: false })}
        bg={toast.variant}
        className="position-fixed bottom-0 end-0 m-3"
      >
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default Login;
